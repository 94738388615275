import Link from "next/link";
import If from "./If";

export default function Button({
  children,
  link = "",
  className = "",
  showIcon = false,
  color = "pink",
  size = "default",
  ...props
}) {
  const colors = {
    pink: "bg-pink text-black",
    red: "bg-red text-white",
    blue: "bg-blue text-white",
    yellow: "bg-transparent text-yellow",
    "yellow-solid": "bg-yellow text-black",
    green: "bg-green text-white",
    white: "bg-transparent text-white",
    black: "bg-black text-white",
  };
  const borderColors = {
    pink: "border-black",
    red: "border-black",
    blue: "border-black",
    yellow: "border-yellow",
    "yellow-solid": "border-yellow",
    green: "border-green",
    white: "border-white",
    black: "border-black",
  };
  const arrowColors = {
    pink: "black",
    red: "white",
    blue: "white",
    yellow: "black",
    green: "white",
    white: "black",
    black: "white",
  };
  return link ? (
    <a
      href={link}
      className={`button ${
        size === "default" ? "px-6 py-3" : "px-3 py-1"
      } w-max max-w-full border-2 ${colors[color || "pink"]} ${
        borderColors[color || "pink"]
      } uppercase flex items-center font-bold gap-4 text-base hover:bg-black hover:text-white hover:border-black transition-all duration-200 ease-in-out`}
      {...props}
    >
      {children}
      <If condition={showIcon}>
        <svg
          className="w-[30px] icon"
          viewBox="0 0 17 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
            fill={arrowColors[color]}
          />
        </svg>
      </If>
    </a>
  ) : (
    <button
      className={`button ${
        size === "default" ? "px-6 py-3" : "px-3 py-1"
      } w-max max-w-full border-2 ${colors[color || "pink"]} ${
        borderColors[color || "pink"]
      } uppercase flex items-center font-bold gap-4 text-base hover:bg-black hover:text-white hover:border-black transition-all duration-200 ease-in-out`}
      {...props}
    >
      {children}
      <If condition={showIcon}>
        <svg
          className="w-[30px] icon"
          viewBox="0 0 17 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
            fill={arrowColors[color]}
          />
        </svg>
      </If>
    </button>
  );
}

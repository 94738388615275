"use client";

import { useEffect, useRef } from "react";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export const ScrubVideo = ({ src = "", className = "", ...props }) => {
  const observerRef = useRef<HTMLVideoElement>(null);

  const ref = useRef(null);
  const backgroundEle = useRef(null);

  useGSAP(
    () => {
      if (!gsap || typeof window === "undefined") {
        return;
      }

      const tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: ref.current,
          start: "top bottom-=25%",
          end: "bottom bottom",
          scrub: 0.5,
          onEnter: () => {
            ref.current.currentTime = 0;
          },
          onEnterBack: () => {
            ref.current.currentTime = ref.current.duration;
          },
          onLeaveBack: () => {
            ref.current.currentTime = 0;
          },
          onLeave: () => {
            console.log("leave");
            ref.current.currentTime = ref.current.duration;
          },
          onUpdate: (self) => {
            if (!ref.current) return;
            const frame = self.progress * (ref?.current?.duration || 0);
            if (ref.current.duration < frame) {
              ref.current.currentTime = ref.current.duration;
              return;
            }
            ref.current.currentTime = frame;
          },
        },
      });

      if (!backgroundEle.current) return;
    },
    {
      dependencies: [ref.current],
    }
  );

  return (
    <video
      ref={ref}
      className={className}
      muted
      playsInline
      src={src}
      {...props}
    />
  );
};

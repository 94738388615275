"use client";

import { useEffect, useRef } from "react";

interface AnimateInProps {
  children?: React.ReactNode;
  className?: string;
  delay?: string;
  as?: string;
  threshold?: number;
  isInterior?: boolean;
}

export default function AnimateIn({
  children = null,
  className = "",
  delay = "",
  as = "div",
  threshold = 0.01,
  isInterior = false,
  ...props
}: AnimateInProps) {
  const observerRef = useRef<HTMLDivElement>(null);

  const Component = as || "div";

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-up");
          } else {
            entry.target.classList.remove("animate-up");
          }
        });
      },
      { threshold }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  return (
    <Component
      className={`will-animate-up ${delay} ${className}`}
      ref={observerRef}
      {...props}
    >
      {children}
    </Component>
  );
}

"use client";

import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion";
import Lighthouse from "./icons/Lighthouse";
import { useRef } from "react";
import { wrap } from "@motionone/utils";

export default function LighthouseScrub({
  baseVelocity = 1.75,
  className = "",
}) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
  // const x = useTransform(baseX, (v) => `${wrap(0, -1, v)}%`);

  const x = useTransform(
    baseX,
    (v) =>
      `${
        v *
        (typeof window !== "undefined" && window.innerWidth < 1000 ? 0.5 : 0.25)
      }%`
  );

  const directionFactor = useRef<number>(1);
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    /**
     * This is what changes the direction of the scroll once we
     * switch scrolling directions.
     */
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    moveBy += directionFactor.current * moveBy * velocityFactor.get();
    if (velocityFactor.get() !== 0) {
      baseX.set(baseX.get() - moveBy);
    }
  });

  return (
    <div className={`w-full overflow-hidden ${className}`}>
      <motion.div
        style={{ x }}
        className="flex items-start w-max gap-[10dvw] pl-[25%]"
      >
        <Lighthouse /> <Lighthouse /> <Lighthouse /> <Lighthouse />{" "}
        <Lighthouse /> <Lighthouse /> <Lighthouse /> <Lighthouse />{" "}
        <Lighthouse />
      </motion.div>
    </div>
  );
}

"use client";

import React, { useEffect, useRef, useState } from "react";
import styles from "./Locations.module.scss";
import H2 from "@/components/H2";
import CircleBackground from "@/components/icons/CircleBackground";
import Map, {
  GeolocateControl,
  MapProvider,
  Marker,
  NavigationControl,
  useMap,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Button } from "@/components/actions/Button";
import AnimateIn from "@/components/AnimateIn";
import For from "@/components/For";
import If from "@/components/If";
import Link from "next/link";
import SelectInput from "@/components/form/Select/Select";
import { TextInput } from "@/components/form/TextInput";
import { useRouter } from "next/navigation";
import useSupercluster from "use-supercluster";
import { SectionHeader } from "@/components/components/SectionHeader";
import LighthouseScrub from "@/components/LighthouseScrub";

const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

interface MarkerProps {
  location: {
    lat: number;
    lon: number;
  };
  title: string;
  description?: string;
  city: string;
  state: string;
  zip: string;
  phone?: string;
  email?: string;
}

interface LocationsProps {
  markers: MarkerProps[];
  isInterior?: boolean;
}

/**
 *
 * Locations
 *
 */
const Locations = ({
  markers = [],
  isInterior = false,
  ...props
}: LocationsProps) => {
  return (
    <MapProvider>
      <TheMap markers={markers} {...props} isInterior={isInterior} />
    </MapProvider>
  );
};

const TheMap = ({
  markers = [],
  isInterior = false,
  ...props
}: LocationsProps) => {
  const router = useRouter();
  const [activeMarkers, setActiveMarkers] = useState(markers);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [zoom, setZoom] = useState(7);
  const [imageWidth, setImageWidth] = useState(70);
  const [showOverlay, setShowOverlay] = useState(false);
  const mapRef = useRef(null);
  const [canScrollZoom, setCanScrollZoom] = useState(false);
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");
  const [viewport, setViewport] = useState({
    latitude: 43.065402754145495,
    longitude: -89.44264292318853,
    zoom: 7,
  });
  const [points, setPoints] = useState([]);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      const _points = (activeMarkers || markers).map((marker) => ({
        type: "Feature",
        properties: { cluster: false, ...marker },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(
              marker?.locationDetails?.location?.lon || marker?.location?.lon
            ),
            parseFloat(
              marker?.locationDetails?.location?.lat || marker?.location?.lat
            ),
          ],
        },
      }));
      const _bounds = mapRef.current
        ? mapRef.current.getMap().getBounds().toArray().flat()
        : null;

      // console.log("Bounds", _bounds);

      setPoints(_points);
      setBounds(_bounds);
    }, 300);
  }, [activeMarkers, viewport]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 10, maxZoom: 20 },
  });

  const zoomToSelectedLoc = (e, location, index) => {
    if (window.innerWidth < 1024) {
      router.push(`/communities/${location.slug}`);
      return;
    }
    // stop event bubble-up which triggers unnecessary events
    e.stopPropagation();
    setSelectedMarker({ location, index });
    // mapRef.current.flyTo({
    //   center: [location?.location.lon, location?.location.lat],
    // });
    setShowOverlay(true);
  };

  const zoomToSelectedCluster = (e, cluster) => {
    console.log("Cluster", cluster);
    mapRef.current.flyTo({
      center: [...cluster?.geometry?.coordinates],
      zoom: supercluster.getClusterExpansionZoom(cluster.id) + 2,
    });
    // const expansionZoom = Math.min(
    //   supercluster.getClusterExpansionZoom(cluster.id),
    //   20
    // );

    // setViewport({
    //   ...viewport,
    //   zoom: viewport.zoom + 5,
    // });
  };

  const getTheme = (theme: string) => {
    if (theme === "Green") return "bg-green";
    if (theme === "Purple") return "bg-campus-purple";
    if (theme === "Blue") return "bg-dark-blue";
    return "bg-dark-blue";
  };

  return (
    <section className={`${styles["community-map"]} bg-white pt-32`} {...props}>
      <div className="w-[1200px] max-w-full mx-auto flex flex-col lg:grid grid-cols-12">
        <div className="col-span-12 stack  relative mb-12">
          <SectionHeader title="Office Locations" accent="orange" />
        </div>

        <div className="col-span-12 md:col-span-4 stack  relative bg-orange mx-4 md:mx-0">
          {/* <CircleBackground className="absolute top-0 right-[-110%] z-0 h-full" /> */}
          <div className="relative z-10 stack gap-4 h-full py-10 md:py-20">
            <For
              each={activeMarkers}
              render={(marker, idx) => {
                return (
                  <div
                    className={`pt-3 pb-4 stack gap-1 px-2 transition-all duration-300 ease-in-out ${
                      marker.title === selectedMarker?.location.title
                        ? "bg-[rgba(255,255,255,0.15)]"
                        : ""
                    } ${
                      idx < activeMarkers.length - 1
                        ? `border-b-2 border-b-gray`
                        : ""
                    } mx-8`}
                    onMouseEnter={() => {
                      setSelectedMarker({ location: marker, index: idx });
                    }}
                    onMouseLeave={() => {
                      setSelectedMarker(null);
                    }}
                  >
                    <h2 className="text-h3 text-black uppercase font-hero font-medium">
                      {marker?.title}
                    </h2>
                    <address className=" not-italic">
                      <p className="text-body text-white font-light">
                        {marker?.address}
                      </p>
                      <p className="text-body text-white font-light">
                        {marker?.city || marker.city},{" "}
                        {marker?.state || marker.state}{" "}
                        {marker?.zip || marker.zip}
                      </p>
                    </address>
                    <If condition={marker?.phone}>
                      <a
                        href={`tel:+1${marker?.phone?.replace(/-/g, "")}`}
                        className="text-body text-white font-light underline"
                      >
                        {marker?.phone}
                      </a>
                    </If>
                    <If condition={marker?.email}>
                      <a
                        href={`mailto:${marker?.email}`}
                        className="text-body lowercase text-white font-light underline"
                      >
                        {marker?.email}
                      </a>
                    </If>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-8 h-full relative">
          <Map
            mapboxAccessToken={mapboxToken}
            mapStyle="mapbox://styles/bairdlb/clxvxyspt04jd01qmf19z6t8l"
            // dragPan={false}
            scrollZoom={false}
            onZoom={(e) => {
              setViewport(e.viewState);
              setZoom(e.viewState.zoom);
              setImageWidth(
                e.viewState.zoom > 10
                  ? e.viewState.zoom * 15
                  : e.viewState.zoom * 15
              );
            }}
            onMove={(e) => {
              setViewport(e.viewState);
            }}
            initialViewState={{
              latitude: 40.27393359993415,
              longitude: -80.17085174410738,
              zoom: 5,
            }}
            maxZoom={20}
            ref={mapRef}
            reuseMaps
            onClick={() => setCanScrollZoom(true)}
          >
            <GeolocateControl position="top-left" />
            <NavigationControl position="bottom-right" />

            {/* {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              console.log("Cluster", cluster, points);
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker cursor-pointer z-[100000000]"
                    style={{
                      width: `${20 + (pointCount / points.length) * 40}px`,
                      height: `${20 + (pointCount / points.length) * 40}px`,
                    }}
                    onClick={(e) => {
                      zoomToSelectedCluster(e, cluster);
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
          })} */}

            {clusters.map((cluster, index) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const { cluster: isCluster, point_count: pointCount } =
                cluster.properties;

              if (isCluster) {
                return (
                  <Marker
                    key={`cluster-${cluster.id}`}
                    latitude={latitude}
                    longitude={longitude}
                  >
                    <div className="cluster-marker cursor-pointer flex flex-col">
                      <button
                        onClick={(e) => {
                          zoomToSelectedCluster(e, cluster);

                          // const expansionZoom = Math.min(
                          //   supercluster.getClusterExpansionZoom(cluster.id),
                          //   20
                          // );

                          // setViewport({
                          //   ...viewport,
                          //   latitude,
                          //   longitude,
                          //   zoom: expansionZoom,
                          // });
                        }}
                        className="pointer-events-auto text-orange hover:text-dark-blue"
                      >
                        <svg
                          width="35"
                          viewBox="0 0 25 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="pointer-events-none"
                        >
                          <g clip-path="url(#clip0_74_2064)">
                            <path
                              d="M24.4821 11.6027C24.4821 17.8178 12.4383 31.8861 12.4383 31.8861C12.4383 31.8861 0.394531 17.8206 0.394531 11.6027C0.394531 5.38477 5.78675 0.349121 12.4383 0.349121C19.0899 0.349121 24.4821 5.38753 24.4821 11.6027Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_74_2064">
                              <rect
                                width="24.0876"
                                height="31.537"
                                fill="white"
                                transform="translate(0.394531 0.349121)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                      {/* <span className="opacity-0">{pointCount}</span> */}
                    </div>
                  </Marker>
                );
              }

              const marker = cluster.properties;
              const lat =
                marker?.locationDetails?.location?.lat || marker?.location?.lat;
              const lon =
                marker?.locationDetails?.location?.lon || marker?.location?.lon;
              if (!lon || !lat) return null;
              const image = marker?.mapImage || marker?.featuredImage || {};
              const calculateOffset = () => {
                // if (marker?.city === "Milwaukee" && zoom > 10) return [50, 20];
                // if (marker?.city === "Janesville" && zoom > 10) return [0, 30];
                // if (marker?.city === "Fond du Lac" && zoom > 10) return [0, 10];
                // if (marker?.city === "New Berlin" && zoom > 10) return [30, 50];

                if (
                  marker?.title?.toLowerCase() === "headquarters" &&
                  zoom <= 11
                ) {
                  return [10, -10];
                }
                return [0, 20];
              };
              // console.log(imageWidth, zoom);
              return (
                <Marker
                  key={marker.title}
                  longitude={lon}
                  latitude={lat}
                  offset={calculateOffset()}
                  anchor="bottom-left"
                >
                  <div
                    className={`cursor-pointer flex flex-col gap-0 items-start overflow-hidden pointer-events-none ${styles.marker}`}
                  >
                    <button
                      type="button"
                      onClick={(e) => zoomToSelectedLoc(e, marker, index)}
                      className="pointer-events-auto text-orange hover:text-dark-blue"
                    >
                      <svg
                        className="pointer-events-none"
                        width="35"
                        viewBox="0 0 25 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_74_2064)">
                          <path
                            d="M24.4821 11.6027C24.4821 17.8178 12.4383 31.8861 12.4383 31.8861C12.4383 31.8861 0.394531 17.8206 0.394531 11.6027C0.394531 5.38477 5.78675 0.349121 12.4383 0.349121C19.0899 0.349121 24.4821 5.38753 24.4821 11.6027Z"
                            className="transition-all duration-300 ease-in-out"
                            fill={
                              selectedMarker?.location?.title === marker.title
                                ? "#173340"
                                : "currentColor"
                            }
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_74_2064">
                            <rect
                              width="24.0876"
                              height="31.537"
                              fill="white"
                              transform="translate(0.394531 0.349121)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </Marker>
              );
            })}
          </Map>
        </div>
      </div>
      <LighthouseScrub className="mt-32" />
    </section>
  );
};

export default Locations;

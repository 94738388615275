"use client";

import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from "./HomeHero.module.scss";
import If from "@/components/If";
import { inView } from "framer-motion";
import { useIntersection } from "@/hooks/useIntersection";
import AnimateTitle from "@/components/AnimateTitle";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

/**
 *
 * HomeHero
 *
 */
const HomeHero = ({
  src = "/video/homepage_web.mp4",
  title = "",
  isHome = false,
  ...props
}) => {
  const { ref } = useIntersection({
    action: () => {
      if (!ref?.current) {
        return;
      }
      ref.current.play();
    },
    remove: () => {
      if (!ref?.current) {
        return;
      }
      ref.current.pause();
    },
  });

  return (
    <>
      <header
        className={`${styles["home-hero"]} relative min-h-[70dvh] md:min-h-[90dvh]`}
        {...props}
      >
        <div className=" w-full h-full min-h-[70dvh] md:min-h-[90dvh] screen">
          <video
            src={src}
            preload="metadata"
            autoPlay
            loop
            muted
            playsInline
            className="object-cover w-full h-full min-h-[70dvh] md:min-h-[90dvh]"
            ref={ref}
            fetchPriority="high"
          />
        </div>

        <If condition={!isHome}>
          <div className="absolute top-0 left-0 w-full h-full grid place-content-center z-[2]">
            <h1 className="text-yellow font-medium uppercase font-hero text-hero fade-in max-w-[100dvw] px-2 w-[800px] leading-none text-center">
              {title}
            </h1>
          </div>
        </If>

        <If condition={isHome}>
          <h1 className="sr-only">POWER ON</h1>
          <svg
            width="594"
            height="376"
            viewBox="0 0 594 376"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-[50%] left-[50%] z-[2] -translate-x-[50%] -translate-y-[50%] max-w-[90dvw]"
          >
            <g clip-path="url(#clip0_74_1863)">
              <path
                d="M89.3323 121.405V197.675L70.1424 213.543H33.0082V270.444H0V105.537H70.1424L89.3323 121.405ZM52.3927 131.303H33.0082V187.776H52.3927L56.3046 184.47V134.59L52.3927 131.284V131.303Z"
                fill="#EEE809"
              />
              <path
                d="M198.867 121.405V254.575L179.891 270.444H125.221L106.031 254.575V121.405L125.221 105.537H179.891L198.867 121.405ZM162.161 131.303H142.971L139.059 134.609V240.574L142.971 243.88H162.161L165.878 240.574V134.609L162.161 131.303Z"
                fill="#EEE809"
              />
              <path
                d="M381.657 105.537L358.341 270.444H312.137L297.288 196.022L282.224 270.444H236.02L212.704 105.537H247.775L262.216 228.186H264.688L284.501 129.242H309.88L329.693 228.186H332.164L346.605 105.537H381.677H381.657Z"
                fill="#EEE809"
              />
              <path
                d="M431.578 133.365V172.53H467.467V199.328H431.578V242.402H479.436V270.444H398.57V105.537H479.436V133.365H431.578Z"
                fill="#EEE809"
              />
              <path
                d="M546.912 210.062H529.376V270.463H496.368V105.537H570.228L589.204 121.405V194.174L576.008 205.103L593.33 270.444H559.504L546.912 210.043V210.062ZM529.376 184.295H552.478L556.196 180.989V134.609L552.478 131.303H529.376V184.276V184.295Z"
                fill="#EEE809"
              />
              <path
                d="M426.109 96.883L392.75 2.91699H305.695V96.883"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M543.253 96.883V2.91699H477.023V96.883"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M258.46 96.883V38.5432L215.857 2.91699H93.1469L50.0767 38.5432V96.883"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M124.17 96.8827V68.1601L132.967 60.751H176.037L184.367 68.1601V96.8827"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M428.309 287.479L458.962 373.083H543.253V287.479"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M50.0767 287.479V337.457L93.1469 373.083H215.857L258.46 337.457V287.479"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M184.367 287.479V305.992L176.037 313.382H132.967L124.17 305.992V287.479"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
              <path
                d="M305.695 287.479V373.083H371.906V287.479"
                stroke="#EEE809"
                stroke-width="3"
                stroke-miterlimit="10"
              />
            </g>
            <defs>
              <clipPath id="clip0_74_1863">
                <rect width="593.33" height="376" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </If>
      </header>
    </>
  );
};

export default HomeHero;

"use client";

import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { EmblaCarouselType, EmblaEventType } from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";

import styles from "./SmallCarousel.module.scss";
import For from "@/components/For";
import Image from "@/components/common/Image";
import clsx from "clsx";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";

/**
 *
 * SmallCarousel
 *
 */
const SmallCarousel = ({ data = {}, ...props }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      align: "start",
      startIndex: 0,
    },
    [Autoplay({ playOnInit: false, delay: 4000 })],
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const logEmblaEvent = useCallback(
    (emblaApi: EmblaCarouselType, eventName: EmblaEventType) => {
      const idx = emblaApi.selectedScrollSnap();
      setCurrentIndex(idx);
    },
    [],
  );

  const { observerRef } = useIntersectionObserver({
    onIntersect: (target) => {
      const autoplay = emblaApi?.plugins()?.autoplay;
      if (!autoplay) return;
      // if (playing) {
      // autoplay.reset();
      // embla?.scrollTo(0);
      autoplay.play();
      // setPlaying(true);
      // console.log("autoplay", autoplay.isPlaying());
      // }
    },
    onNotIntersect: (target) => {
      const autoplay = emblaApi?.plugins()?.autoplay;
      if (!autoplay) return;
      autoplay.stop();
      // setPlaying(false);
      // console.log("autoplay", autoplay.isPlaying());
    },
    threshold: 0.5,
  });

  const scrollPrev = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    console.log(emblaApi);
    emblaApi.scrollPrev();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    setTimeout(() => {
      emblaApi?.reInit();
    }, 300);

    emblaApi.on("select", () => {
      // console.log("select");
      setCurrentIndex(emblaApi.selectedScrollSnap());
    });
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("slidesInView", logEmblaEvent);
  }, [emblaApi, logEmblaEvent]);

  const thumbnails = data?.carouselCollection?.items || [];
  return (
    <div
      className={`${styles["small-carousel"]} overflow-hidden`}
      {...props}
      ref={observerRef}
    >
      <div ref={emblaRef} className="w-full overflow-visible">
        <div className={clsx("embla__container flex gap-0 w-full")}>
          <For
            each={thumbnails}
            render={(thumb, idx) => {
              return (
                <Image
                  className="w-full flex-full object-cover"
                  src={thumb?.url}
                  alt={thumb?.title}
                  width={thumb?.width}
                  height={thumb?.height}
                  loading="lazy"
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex justify-center gap-2 mt-4 items-center">
        <For
          each={thumbnails}
          render={(thumb, idx) => {
            return (
              <button
                onClick={() => {
                  emblaApi?.scrollTo(idx);
                  setCurrentIndex(idx);
                }}
                className={`border border-text carousel-btn ${
                  currentIndex === idx ? "bg-text" : "bg-transparent"
                } aspect-square w-[10px] h-[10px] rounded-full overflow-hidden`}
              >
                {idx}
              </button>
            );
          }}
        />
      </div>
    </div>
  );
};

export default SmallCarousel;

import React from "react";
import styles from "./SectionHeader.module.scss";
import WordMark from "@/components/icons/WordMark";
import AnimateIn from "@/components/AnimateIn";
import AnimateTitle from "@/components/AnimateTitle";
import If from "@/components/If";
import RichText from "@/components/RichText";

const colorMap = {
  blue: "text-blue",
  yellow: "text-yellow",
  orange: "text-orange",
};

/**
 *
 * SectionHeader
 *
 */
const SectionHeader = ({
  title = "",
  accent = "blue",
  subhead = "",
  description = "",
  onDark = false,
  alternate = false,
  align = "center",
  backgroundColor = "white",
  anchor = "",
  ...props
}) => {
  return (
    <div className="stack gap-8" id={anchor}>
      <header
        className={`stack gap-6 px-gutter md:px-0 ${
          align === "center"
            ? "narrow-container"
            : " w-[800px] max-w-full mx-auto"
        }`}
      >
        <If condition={!alternate}>
          <AnimateTitle
            title={title}
            as="h2"
            className={`text-h2 font-hero uppercase ${
              align === "center"
                ? "justify-center text-center w-[800px]"
                : "w-full"
            } font-medium max-w-full mx-auto ${
              onDark && backgroundColor.toLowerCase() !== "brown"
                ? "text-white"
                : "text-black"
            }`}
          />
        </If>

        <If condition={alternate}>
          <AnimateTitle
            title={title}
            as="h2"
            className={`text-h3 bg-black  pb-4 pt-5 font-hero uppercase ${
              align === "center"
                ? "justify-center text-center w-[clamp(max-content, 10vw,800px)]"
                : "w-full"
            } font-medium  px-16 max-w-full mx-auto text-white quote-title`}
          />
        </If>
      </header>
      <If condition={subhead || description}>
        <div
          className={`stack gap-3 ${
            align === "center"
              ? "narrow-container"
              : " w-[800px] max-w-full mx-auto px-gutter md:px-0"
          }`}
        >
          <If condition={subhead}>
            <AnimateTitle
              as="h3"
              title={subhead}
              delay="delay-[0.5]"
              className={`text-h3 font-hero uppercase ${
                align === "center"
                  ? "justify-center text-center w-[800px] mx-auto px-gutter md:px-0"
                  : "w-full"
              } font-medium  max-w-full  ${
                onDark && backgroundColor.toLowerCase() !== "brown"
                  ? "text-white"
                  : "text-black"
              }`}
            />
          </If>

          <If condition={!description?.json}>
            <AnimateIn
              as="div"
              className={`text-body max-text ${
                align === "center"
                  ? "justify-center text-center mx-auto px-gutter md:px-0"
                  : "w-full"
              }   ${onDark ? "text-white" : ""}`}
              delay="0.75"
            >
              {description}
            </AnimateIn>
          </If>

          <If condition={description?.json}>
            <AnimateIn
              as="div"
              className={`text-body stack gap-4 max-text mt-6 ${
                align === "center"
                  ? "justify-center text-center mx-auto px-gutter md:px-0"
                  : "w-full"
              }   ${onDark ? "text-white" : ""}`}
              delay="0.75"
            >
              <RichText json={description?.json} />
            </AnimateIn>
          </If>
        </div>
      </If>
    </div>
  );
};

export default SectionHeader;

export default function Lighthouse() {
  return (
    <svg
      className="w-[200dvw] md:w-[100dvw]"
      viewBox="0 0 2219 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.4583 211.387V0H0V256H178.846L161.68 211.387H55.4583Z"
        fill="#D4D4D4"
      />
      <path d="M268.195 0H212.737V256H268.195V0Z" fill="#D4D4D4" />
      <path
        d="M718.073 105.645L622.268 105.693V0H566.81V256H622.268V150.258H718.073V256H773.531V0H718.073V105.645Z"
        fill="#D4D4D4"
      />
      <path
        d="M1179.54 105.645H1083.74V0H1028.28V256H1083.74V150.258H1179.54V256H1235V0H1179.54V105.645Z"
        fill="#D4D4D4"
      />
      <path
        d="M1669.86 211.338H1582.81V0H1527.35V222.284L1561.29 256H1691.48L1725.32 222.284V0H1669.86V211.338Z"
        fill="#D4D4D4"
      />
      <path
        d="M348.252 0L314.361 33.7164V222.284L348.252 256H489.637L523.577 222.284V111.166H435.646L415.79 156.121H468.217V211.338L369.82 211.387V62.6929L348.497 44.662H468.119V77.4499H523.577V33.7164L490.273 0H348.252Z"
        fill="#D4D4D4"
      />
      <path
        d="M814.269 44.6131H875.547V256H931.005V62.6929L909.634 44.662H988.518L1007.88 0H794.902L814.269 44.6131Z"
        fill="#D4D4D4"
      />
      <path
        d="M1310.66 0L1276.72 33.7164V222.284L1310.66 256H1447.05L1480.99 222.284V33.7164L1447.05 0H1310.66ZM1425.53 211.338H1332.22V62.6929L1310.9 44.662H1425.58V211.387L1425.53 211.338Z"
        fill="#D4D4D4"
      />
      <path
        d="M1825.48 105.645V62.6929L1804.11 44.662H1919.82V74.0294H1975.27V33.7164L1941.33 0H1804.06L1770.17 33.7164V118.985L1800.24 150.258H1919.82V211.338H1825.43L1825.48 182.313H1770.12V222.284L1804.11 256H1941.48L1975.27 222.284V132.667L1948.28 105.645H1825.48Z"
        fill="#D4D4D4"
      />
      <path
        d="M2021.05 256H2218.82V211.387H2076.51V150.258H2209.78V105.645H2076.51V62.6929L2055.24 44.662H2218.82V0H2021.05V256Z"
        fill="#D4D4D4"
      />
    </svg>
  );
}

"use client";

import React, { useEffect, useState } from "react";
import styles from "./Projects.module.scss";
import { SectionHeader } from "@/components/components/SectionHeader";
import { Carousel } from "../Carousel";
import { IndustryDropdown } from "@/components/form/IndustryDropdown";
import Button from "@/components/Button";
import If from "@/components/If";

/**
 *
 * Projects
 *
 */
const UI = ({ showLearnMore = true, allProjects = [], ...props }) => {
  const [activeIndustry, setActiveIndustry] = useState("All");
  const [projects, setProjects] = useState(allProjects);

  useEffect(() => {
    setProjects(
      allProjects.filter(
        (project) =>
          activeIndustry.toLowerCase() === "all" ||
          activeIndustry === project.sector,
      ),
    );
  }, [activeIndustry, allProjects]);

  return (
    <section
      className={`${styles.projects} bg-black flex flex-col gap-12 py-24 md:py-32`}
      {...props}
      id="projects"
    >
      <div className="px-gutter stack gap-12">
        <SectionHeader title="Large Projects" accent="orange" onDark />

        <IndustryDropdown onChange={(filter) => setActiveIndustry(filter)} />
      </div>

      <Carousel
        key={activeIndustry}
        data={{
          projectsCollection: {
            items: projects,
          },
        }}
        industry={activeIndustry}
      />

      <If condition={showLearnMore}>
        <div className="flex justify-center">
          <Button color="white" link="/what-we-do">
            Learn More
          </Button>
        </div>
      </If>
    </section>
  );
};

export default UI;

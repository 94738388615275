"use client";

import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import If from "@/components/If";
import H2 from "@/components/H2";
import { useLenis } from "lenis/react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import RichText from "@/components/RichText";

gsap.registerPlugin(ScrollTrigger);

let timer = null;

const useGsapTimeline = (container, items, baseLine) => {
  const [currentSelected, setCurrentSelected] = useState(0);
  const tlRef = useRef(null);
  const [show, setShow] = useState(false);
  const [direction, setDirection] = useState(0);
  useEffect(() => {
    if (!container.current || items.length === 0 || !baseLine.current) return;

    // ScrollTrigger.normalizeScroll(true);

    const containerWidth = container.current.scrollWidth;
    const viewportWidth = window.innerWidth;
    const bufferWidth = viewportWidth; // 30% of viewport as buffer

    const tl = gsap.timeline({
      defaults: { ease: "none" },
      scrollTrigger: {
        trigger: container.current,
        invalidateOnRefresh: true,
        normalizeScroll: true,
        end: () => `+=${containerWidth + viewportWidth}`,
        scrub: true,
        pin: true,
        onEnter: () => {
          baseLine.current.style.opacity = 1;
          setShow(true);
        },
        onEnterBack: () => {
          baseLine.current.style.opacity = 1;
          setShow(true);
        },
        onLeaveBack: () => {
          baseLine.current.style.opacity = 0;
          setShow(false);
        },
        onLeave: () => {
          baseLine.current.style.opacity = 0;
          setShow(false);
        },
        anticipatePin: 1,
      },
    });
    tlRef.current = tl;

    // Animate the base line drawing
    tl.fromTo(
      baseLine.current,
      { scaleX: 0.2, transformOrigin: "left center" },
      {
        scaleX: 1,
        ease: "none",
      },
      0,
    );

    // Animate container
    tl.to(
      container.current,
      {
        x: () => -(containerWidth - viewportWidth * 0.5),
        ease: "none",
      },
      0,
    );

    // Animate each event
    items.forEach((item, index) => {
      // console.log(item.lineRef.current);
      const t2 = gsap.timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          containerAnimation: tl,
          trigger: item.lineRef.current,
          start: `left left+=${viewportWidth * 0.75 - 100}`,
          invalidateOnRefresh: true,
          // markers: true,
          id: `event-${index}`,
          end: `left center`,
          scrub: true,
          // pin: true,
          anticipatePin: 1,
        },
      });
      t2.fromTo(
        item.lineRef.current,
        { scaleY: 0, transformOrigin: "bottom center" },
        {
          scaleY: 1,
          ease: "none",
          duration: 0.1,
          // ease: "power1.inOut",
        },
        "-=0.01",
      );

      t2.fromTo(
        item.contentRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          ease: "none",
          duration: 0.2,
          // ease: "power1.inOut",
        },
        `>-0.1`,
      );
    });

    return () => {
      tl.kill();
    };
  }, [container, items, baseLine]);

  return { ref: tlRef.current, show, direction };
};

const TimelineEvent = React.forwardRef(
  ({ year, event, img, isSelected = false }, ref) => {
    if (!ref) return null;
    const { lineRef, contentRef } = ref;
    return (
      <div className="flex gap-0 h-max" style={{ flex: "0 0 500px" }}>
        <div className="flex flex-col">
          <div ref={lineRef} className="w-1 h-full bg-white"></div>
        </div>
        <div ref={contentRef} className="px-8 pb-10 stack gap-4">
          <div className=" text-[3rem] font-hero text-white leading-none">
            {year}
          </div>
          <RichText
            json={event?.json}
            className="text-white text-lg lg:text-xl max-w-[325px]"
          />
          <If condition={img}>
            <img
              src={`${img}`}
              alt={event}
              className="w-[50%] max-h-[150px] object-contain object-left"
              width={800}
              height={600}
              loading="lazy"
            />
          </If>
        </div>
      </div>
    );
  },
);

const History = ({ data = [] }) => {
  const containerRef = useRef(null);
  const baseLineRef = useRef(null);
  const [itemRefs, setItemRefs] = useState([]);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [direction, setDirection] = useState("down");

  const lenis = useLenis();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > prevScrollPos;
      setDirection(isScrollingDown ? "down" : "up");
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    setItemRefs(
      data.map(() => ({
        lineRef: React.createRef(),
        contentRef: React.createRef(),
      })),
    );
  }, [data]);

  const { show, ref } = useGsapTimeline(containerRef, itemRefs, baseLineRef);

  const handleSkipBack = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const containerHeight = containerRef.current.scrollWidth;
    const top =
      direction === "up"
        ? document.body.scrollHeight - containerRef.current.scrollWidth
        : document.body.scrollHeight;

    lenis?.scrollTo(document.querySelector("#start").offsetTop, {
      duration: 2,
    });
    // ScrollTrigger.update();
  };

  const handleSkipForward = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const containerHeight = containerRef.current.scrollWidth;
    const top =
      direction === "up"
        ? document.body.scrollHeight - containerRef.current.scrollWidth
        : document.body.scrollHeight;

    lenis?.scrollTo(document.body.scrollHeight, { duration: 2 });
    // ScrollTrigger.update();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "ArrowRight" && index < data.length - 1) {
      itemRefs[index + 1].contentRef.current.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      itemRefs[index - 1].contentRef.current.focus();
    }
  };

  return (
    <div
      className="relative overflow-x-hidden bg-dark-blue"
      role="region"
      aria-label="Timeline"
      id="start"
    >
      <h2
        className={`font-normal uppercase text-[2.75rem] lg:text-h2 text-white font-hero text-center w-full px-[1rem] fixed top-[80px] z-10 transition-all ease-in-out duration-300 ${
          show ? "opacity-100" : "opacity-0"
        }`}
      >
        Through the years
      </h2>
      <div
        className={`fixed left-4 bottom-4 z-[100] flex gap-6 transition-all ease-in-out duration-300 ${
          show ? "opacity-100" : "opacity-0"
        }`}
      >
        <button className={`text-yellow`} onClick={handleSkipBack}>
          <ArrowLeft size={42} />
        </button>
        <button className={`text-yellow`} onClick={handleSkipForward}>
          <ArrowRight size={42} />
        </button>
      </div>
      <div className="w-full border-b-3 border-b-white">
        <div
          ref={containerRef}
          className="flex px-8 relative  h-timeline items-end"
          style={{ paddingLeft: "75vw" }}
        >
          {data.map((item, index) => (
            <TimelineEvent
              key={index}
              year={item.year}
              event={item.event || item.description}
              img={item.img || item.image?.url}
              ref={itemRefs[index]}
              onKeyDown={(e) => handleKeyDown(e, index)}
              tabIndex={0}
              role="listitem"
              aria-label={`Year ${item.year}: ${item.event}`}
            />
          ))}
        </div>
        <div
          ref={baseLineRef}
          className="relative bottom-[150px] left-0 w-full h-1 bg-white hidden"
          style={{ width: "100dvw", opacity: 0 }}
          aria-hidden="true"
          id="end"
        ></div>
      </div>
    </div>
  );
};

export default History;

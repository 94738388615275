"use client";

import React from "react";
import styles from "./People.module.scss";
import { SectionHeader } from "@/components/components/SectionHeader";
import For from "@/components/For";
import Button from "@/components/Button";
import LinkedIn from "@/components/icons/LinkedIn";
import { EnvelopeClosedIcon } from "@radix-ui/react-icons";
import If from "@/components/If";
import { X } from "lucide-react";
import Image from "@/components/common/Image";

/**
 *
 * People
 *
 */
const Interactive = ({ people = [], ...props }) => {
  const [selectedPerson, setSelectedPerson] = React.useState(null);
  return (
    <section
      className={`${styles.people} bg-black py-24 md:py-32  stack gap-16`}
      {...props}
    >
      <SectionHeader title="Our People" accent="blue" onDark align="center" />

      {/* <div className="divider" /> */}

      {/* Grid of images with title and description */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-0 people-grid w-[1200px] max-w-full mx-auto relative">
        <For
          each={people}
          render={(person, index) => (
            <button
              className={`relative grid-hover aspect-[288/430]`}
              onClick={() => {
                if (window.innerWidth < 769) {
                  setSelectedPerson(person);
                }
              }}
            >
              <Image
                src={person.image?.url}
                alt={person.name}
                className="object-cover w-full"
                width={person.image?.width}
                height={person.image?.height}
                loading="lazy"
                maxWidth="500"
              />
              <div className="stack gap-2 py-6 absolute left-0 top-0 w-full h-full bg-black bg-opacity-40 overlay grid place-content-center">
                <h2 className="text-h3 font-hero text-white uppercase font-medium text-center">
                  {person.name}
                </h2>
                <p className="text-p text-white text-center">
                  {person.jobTitle}
                </p>
                <div className="flex gap-4 items-center text-white justify-center">
                  <If condition={person.linkedInUrl}>
                    <a
                      href={person.linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      <LinkedIn />
                    </a>
                  </If>
                </div>
              </div>
            </button>
          )}
        />
        <If condition={selectedPerson}>
          <div className="absolute inset-0">
            <div className="w-full">
              <button
                className="absolute top-0 right-0 p-4 z-[5] text-white stack gap-0 leading-none items-center"
                onClick={() => {
                  setSelectedPerson(null);
                }}
              >
                <X size={40} />
                Close
              </button>
              <Image
                src={selectedPerson?.image?.url}
                alt={selectedPerson?.name}
                className="object-cover w-full"
                width={selectedPerson?.image?.width}
                height={selectedPerson?.image?.height}
                loading="lazy"
              />

              <div className="stack gap-2 py-6 absolute left-0 top-0 w-full h-full bg-black bg-opacity-40 overlay grid place-content-center z-[4] opacity-100 open">
                <h2 className="text-h3 font-hero text-white uppercase font-medium text-center">
                  {selectedPerson?.name}
                </h2>
                <p className="text-p text-white text-center">
                  {selectedPerson?.title}
                </p>
                <div className="flex gap-4 items-center text-white justify-center">
                  <If condition={selectedPerson?.linkedin}>
                    <a
                      href={selectedPerson?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      <LinkedIn />
                    </a>
                  </If>
                </div>
              </div>
            </div>
          </div>
        </If>
      </div>

      <div className="flex justify-center px-gutter">
        <Button color="white" href="/join-our-team" className="mx-auto block">
          Join Our Team
        </Button>
      </div>
    </section>
  );
};

export default Interactive;

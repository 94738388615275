import React from "react";

type Props = {
  each: any[];
  render: (item: any, index: number) => React.ReactNode;
};

const For: React.FC<Props> = ({
  each,
  render = (item) => {
    <></>;
  },
}) => {
  return (
    <>
      {(each || [])?.map((item, index) => (
        <React.Fragment key={index}>{render(item, index)}</React.Fragment>
      ))}
    </>
  );
};

export default For;

import React from "react";
import * as Select from "@radix-ui/react-select";
import clsx from "clsx";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";

interface IndustryDropdownProps {
  onChange?: (value: string) => void;
}

const IndustryDropdown = ({ onChange }: IndustryDropdownProps) => (
  <Select.Root onValueChange={(value) => onChange?.(value)}>
    <Select.Trigger
      className="max-w-full px-2 py-2 leading-none border-[3px] text-white font-hero uppercase text-[1.75rem] border-yellow w-[392px] mx-auto flex justify-between items-center"
      aria-label="Industry"
    >
      <Select.Value placeholder="ALL" />
      <Select.Icon>
        <svg
          width="16"
          height="7"
          viewBox="0 0 16 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5443 0.0571289H0.818359L8.32294 6.12077L15.5443 0.0571289Z"
            fill="#EEE809"
          />
        </svg>
      </Select.Icon>
    </Select.Trigger>
    <Select.Portal>
      <Select.Content
        className="overflow-hidden bg-black text-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]"
        position="popper"
      >
        <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
          <ChevronUpIcon />
        </Select.ScrollUpButton>
        <Select.Viewport className="p-[5px]">
          <Select.Group>
            <SelectItem value="All">All</SelectItem>
            <SelectItem value="Industrial">Industrial</SelectItem>
            {/* <SelectItem value="Sports & Entertainment">
              Sports & Entertainment
            </SelectItem> */}
            {/* <SelectItem value="Hospitality & Gaming">
              Hospitality & Gaming
            </SelectItem> */}
            <SelectItem value="Mission Critical">Mission Critical</SelectItem>
            {/* <SelectItem value="Oil & Gas">Oil & Gas</SelectItem> */}
            <SelectItem value="Commercial">Commercial</SelectItem>
            {/* <SelectItem value="Education">Education</SelectItem> */}
            <SelectItem value="Healthcare">Healthcare</SelectItem>
            {/* <SelectItem value="Service & Accounts">
              Service & Accounts
            </SelectItem> */}
            {/* <SelectItem value="Telecommunications">
              Telecommunications
            </SelectItem>
            <SelectItem value="Water & Waste Treatment">
              Water & Waste Treatment
            </SelectItem> */}
          </Select.Group>
        </Select.Viewport>
        <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
          <ChevronDownIcon />
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  </Select.Root>
);

const SelectItem = React.forwardRef(
  ({ children, className = "", ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={clsx(
          "text-[1.75rem] font-hero uppercase py-4 px-4 hover:bg-yellow hover:text-black  leading-none",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default IndustryDropdown;

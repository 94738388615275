"use client";

import React, { useEffect, useState } from "react";
import styles from "./Details.module.scss";
import * as Accordion from "@radix-ui/react-accordion";
import { Plus } from "lucide-react";
import RichText from "@/components/common/RichText";
import If from "@/components/If";
/**
 *
 * Details
 *
 */
const Details = ({ items = [], className = "", ...props }) => {
  const [currentOpen, setCurrentOpen] = useState("");

  useEffect(() => {
    if (!items) return;

    items.forEach((item) => {
      if (item.startExpanded) {
        setCurrentOpen(item.title);
      }
    });
  }, []);
  console.log(currentOpen);
  return (
    <section className={`${styles.details}`} {...props}>
      <Accordion.Root
        key={currentOpen}
        className={`w-full ${className}`}
        onValueChange={(value) => {
          setCurrentOpen(value);
        }}
        defaultValue={currentOpen}
        collapsible
      >
        {items.map((item, index) => (
          <Accordion.Item
            key={item.title}
            value={item.title}
            className="border-t-4 border-t-gray py-8 w-full block"
          >
            <Accordion.Header>
              <Accordion.Trigger
                disabled={!item.canExpand}
                className="trigger flex justify-between w-full data-[state=open]:open"
              >
                <h2 className="text-h3 uppercase font-hero font-medium">
                  {item.title}
                </h2>
                <If condition={item.canExpand}>
                  <Plus
                    strokeWidth={3}
                    strokeLinecap="square"
                    color="#ADADAD"
                    size={32}
                    className={`transtion-all ease-in-out duration-300 ${
                      currentOpen === item.title ? "rotate-45" : ""
                    } relative block`}
                  />
                </If>
              </Accordion.Trigger>
            </Accordion.Header>
            <Accordion.Content className="detail-content pt-5 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[#616161] max-w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-16 max-w-full w-[726px] mx-auto">
                <If condition={item.detailLeftColumn?.json}>
                  <div
                    className={`stack gap-6 ${!item.detailRightColumn?.json ? "md:col-span-2" : ""}`}
                  >
                    <RichText json={item.detailLeftColumn?.json} />
                  </div>
                </If>

                <If condition={item.detailRightColumn?.json}>
                  <div className="stack gap-6">
                    <RichText json={item.detailRightColumn?.json} />
                  </div>
                </If>
              </div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </section>
  );
};

export default Details;

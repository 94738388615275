"use client";

import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import styles from "./ImageCarousel.module.scss";
import For from "@/components/For";
import Image from "@/components/Image";
import clsx from "clsx";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";

/**
 *
 * ImageCarousel
 *
 */
const ImageCarousel = ({ data = {}, isInterior = false, ...props }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
      align: "center",
      startIndex: 1,
      containScroll: false,
    },
    [Autoplay({ playOnInit: false, delay: 4000 })],
  );
  const [currentIndex, setCurrentIndex] = useState(1);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    console.log(emblaApi);
    emblaApi.scrollPrev();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    setTimeout(() => {
      emblaApi?.reInit();
    }, 300);
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      // Initialize Embla Carousel
      emblaApi.on("select", () => {
        // console.log("select");
        setCurrentIndex(emblaApi.selectedScrollSnap());
        // onChange(emblaApi.selectedScrollSnap());
      });
      emblaApi.on("settle", () => {
        // setSelected(embla.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  const { observerRef } = useIntersectionObserver({
    onIntersect: (target) => {
      const autoplay = emblaApi?.plugins()?.autoplay;
      if (!autoplay) return;
      // if (playing) {
      // autoplay.reset();
      // embla?.scrollTo(0);
      autoplay.play();
      // setPlaying(true);
      // console.log("autoplay", autoplay.isPlaying());
      // }
    },
    onNotIntersect: (target) => {
      const autoplay = emblaApi?.plugins()?.autoplay;
      if (!autoplay) return;
      autoplay.stop();
      // setPlaying(false);
      // console.log("autoplay", autoplay.isPlaying());
    },
    threshold: 0.5,
  });

  const thumbnails =
    data?.imagesCollection?.items || data?.carouselCollection?.items || [];

  return (
    <section
      className={`${styles.carousel} stack gap-6 relative py-24 md:py-32 overflow-hidden w-full`}
      {...props}
      ref={observerRef}
    >
      <div className="flex gap-4 flex-col md:flex-row">
        <div ref={emblaRef} className="w-full overflow-visible">
          <div className={clsx("embla__container flex w-full")}>
            <For
              each={thumbnails}
              render={(thumbnail, index) => (
                <div
                  className={`stack gap-12 w-[90dvw] ld:w-[50dvw] ml-12 embla__slide ${
                    styles.slide
                  } ${currentIndex === index ? styles.active : ""}`}
                >
                  <Image
                    src={thumbnail?.url}
                    alt={`Thumbnail ${index}`}
                    fillContainer
                    className={`transition-all duration-500 ease-in-out ${
                      currentIndex === index ? "filter-none" : " grayscale"
                    }`}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <button
          className="arrow right-4 top-[50%] -translate-y-[50%] absolute text-white hover:text-orange hidden md:block"
          onClick={scrollNext}
        >
          <svg
            width="38"
            height="71"
            viewBox="0 0 38 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.94531 3L32.9994 35.8154L2.94531 68.6309"
              stroke="currentColor"
              strokeWidth="6"
              className="transition-all duration-300 ease-in-out"
            />
          </svg>

          <span className="sr-only">Next</span>
        </button>
        <button
          className="arrow left-4 top-[50%] -translate-y-[50%] absolute text-white hover:text-orange hidden md:block"
          onClick={scrollPrev}
        >
          <svg
            width="37"
            height="71"
            viewBox="0 0 37 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.6362 3L4.58219 35.8154L34.6362 68.6309"
              stroke="currentColor"
              strokeWidth="6"
              className="transition-all duration-300 ease-in-out"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>
      </div>
      <div className="flex justify-center gap-2 mt-4 items-center">
        <For
          each={thumbnails}
          render={(thumb, idx) => {
            return (
              <button
                onClick={() => {
                  emblaApi?.scrollTo(idx);
                  setCurrentIndex(idx);
                }}
                className={`border border-text carousel-btn ${
                  currentIndex === idx ? "bg-text" : "bg-transparent"
                } aspect-square w-[10px] h-[10px] rounded-full overflow-hidden`}
              >
                {idx}
              </button>
            );
          }}
        />
      </div>
    </section>
  );
};

export default ImageCarousel;

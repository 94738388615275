"use client";

import { useEffect, useRef } from "react";

interface AnimateInProps {
  children?: React.ReactNode;
  className?: string;
  delay?: string;
  as?: string;
  threshold?: number;
  isInterior?: boolean;
  title?: string;
}

export default function AnimateTitle({
  title = "",
  className = "",
  delay = "",
  as = "div",
  threshold = 0.01,
  isInterior = false,
  ...props
}: AnimateInProps) {
  const observerRef = useRef<HTMLDivElement>(null);

  const Component = as || "div";

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("word-animate-up");
          } else {
            entry.target.classList.remove("word-animate-up");
          }
        });
      },
      { threshold },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  if (!title) return null;

  return (
    <Component
      className={`word-will-animate-up flex gap-[0.25em] leading-[0.8] pt-1 flex-wrap hide-text  ${delay} ${className}`}
      ref={observerRef}
      {...props}
    >
      {title.split(" ").map((word, index) => (
        <span className="word" key={index}>
          {word}
        </span>
      ))}
    </Component>
  );
}

"use client";

import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";

import styles from "./Carousel.module.scss";
import For from "@/components/For";
import H2 from "@/components/H2";
import AnimateIn from "@/components/AnimateIn";
import Image from "@/components/Image";
import RightArrow from "@/components/RightArrow";
import clsx from "clsx";
import { Minus, Plus } from "react-feather";
import If from "@/components/If";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import RichText from "@/components/RichText";

/**
 *
 * Carousel
 *
 */
const Carousel = ({
  data = {},
  isInterior = false,
  industry = "All",
  ...props
}) => {
  const thumbnails = data?.projectsCollection?.items || [];

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "center",
    startIndex: 0,
    containScroll: false,
  });
  const [currentIndex, setCurrentIndex] = useState(1);
  const [openProject, setOpenProject] = useState(null);

  const scrollPrev = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollPrev();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    emblaApi.scrollNext();
    setCurrentIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    setTimeout(() => {
      emblaApi?.reInit();
    }, 300);
  }, [emblaApi]);

  useEffect(() => {
    if (!openProject) {
      setOpenProject(null);
      return;
    }
    const nextProject = thumbnails[currentIndex];
    setOpenProject(nextProject);
  }, [currentIndex]);

  useEffect(() => {
    if (emblaApi) {
      // Initialize Embla Carousel
      emblaApi.on("select", () => {
        // console.log("select");
        setCurrentIndex(emblaApi.selectedScrollSnap());
        // onChange(emblaApi.selectedScrollSnap());
      });
      emblaApi.on("settle", () => {
        // setSelected(embla.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      setCurrentIndex(0);
      setTimeout(() => {
        emblaApi.scrollTo(0);
      }, 1000);
    }
  }, [emblaApi, industry, data]);

  return (
    <section
      className={`${styles.carousel} stack gap-6 relative overflow-hidden`}
      {...props}
    >
      <div className="flex gap-4 flex-col md:flex-row">
        <div ref={emblaRef} className="w-full overflow-visible">
          <div className={clsx("embla__container flex w-full")}>
            <For
              each={thumbnails}
              render={(thumbnail, index) => (
                <div
                  className={`stack gap-6 w-[700px] max-w-[80dvw] embla__slide ml-12 ${
                    styles.slide
                  } ${currentIndex === index ? styles.active : ""}`}
                >
                  <Image
                    src={thumbnail?.image?.url}
                    width={thumbnail?.image?.width}
                    height={thumbnail?.image?.height}
                    alt={thumbnail?.image?.description}
                    className={`transition-all w-full duration-500 ease-in-out aspect-[847/513] ${
                      currentIndex === index ? "filter-none" : " grayscale"
                    }`}
                    imgClassName="aspect-[847/513] w-full"
                  />
                  <div
                    className={`flex justify-between pb-8 border-b border-white transition-all duration-500 ease-in-out ${
                      styles.title
                    } ${currentIndex === index ? "opacity-100" : "opacity-0"}`}
                  >
                    <If condition={thumbnail?.canExpand !== false}>
                      <button
                        className="flex justify-between w-full"
                        onClick={() => {
                          setOpenProject(
                            thumbnail.title === openProject?.title
                              ? null
                              : thumbnail,
                          );
                        }}
                      >
                        <h2 className="text-h3 text-yellow uppercase font-hero font-medium">
                          {thumbnail.title}
                        </h2>

                        <If
                          condition={
                            openProject &&
                            thumbnail.title === openProject?.title
                          }
                        >
                          <Minus className="text-white" />
                        </If>

                        <If
                          condition={
                            !openProject ||
                            thumbnail.title !== openProject?.title
                          }
                        >
                          <Plus className="text-white" />
                        </If>
                      </button>
                    </If>

                    <If condition={thumbnail?.canExpand === false}>
                      <h2 className="text-h3 text-yellow uppercase font-hero font-medium">
                        {thumbnail.title}
                      </h2>
                    </If>
                  </div>

                  <If
                    condition={
                      openProject &&
                      thumbnail.title === openProject?.title &&
                      openProject?.canExpand
                    }
                  >
                    <div className="will-fade-in fade-in stack gap-8 delay-5">
                      <If condition={openProject?.text}>
                        <div className="text-white">
                          <RichText json={openProject?.text?.json} />
                        </div>
                        <div className="divider full"></div>
                      </If>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-full w-[726px] mx-auto">
                        <div className="stack gap-4">
                          <If condition={openProject?.description}>
                            <div>
                              <h3 className="text-base text-[#868686]">
                                Description
                              </h3>
                              <p className="text-white">
                                {openProject?.description}
                              </p>
                            </div>
                          </If>

                          <If condition={openProject?.location}>
                            <div>
                              <h3 className="text-base text-[#868686]">
                                Location
                              </h3>
                              <p className="text-white">
                                {openProject?.location}
                              </p>
                            </div>
                          </If>

                          <If condition={openProject?.generalContractor}>
                            <div>
                              <h3 className="text-base text-[#868686]">
                                General Contractor
                              </h3>
                              <p className="text-white">
                                {openProject?.generalContractor}
                              </p>
                            </div>
                          </If>

                          {/* <div>
                            <h3 className="text-base text-[#868686]">Budget</h3>
                            <p className="text-white">$50 Million</p>
                          </div> */}
                          <If condition={openProject?.projectSize}>
                            <div>
                              <h3 className="text-base text-[#868686]">
                                Project Size
                              </h3>
                              {openProject?.projectSize.map((size, idx) => (
                                <p key={`size-${idx}`} className="text-white">
                                  {size}
                                </p>
                              ))}
                            </div>
                          </If>
                        </div>

                        <div className="stack gap-4">
                          <If condition={openProject?.sector}>
                            <div>
                              <h3 className="text-base text-[#868686]">
                                Sector
                              </h3>
                              <p className="text-white">
                                {openProject?.sector}
                              </p>
                            </div>
                          </If>

                          <If
                            condition={
                              openProject?.services &&
                              openProject?.services?.length > 0
                            }
                          >
                            <div>
                              <h3 className="text-base text-[#868686]">
                                Services
                              </h3>
                              <p className="text-white flex flex-col">
                                {openProject?.services?.map((service, idx) => (
                                  <span key={idx}>{service}</span>
                                ))}
                                {/* BIM
                              <br />
                              Engineering */}
                              </p>
                            </div>
                          </If>

                          <If
                            condition={
                              openProject?.valueAdd &&
                              openProject?.valueAdd?.length > 0
                            }
                          >
                            <div>
                              <h3 className="text-base text-[#868686]">
                                Value Add
                              </h3>
                              <p className="text-white flex flex-col">
                                {openProject?.valueAdd?.map((service, idx) => (
                                  <span key={idx}>{service}</span>
                                ))}
                              </p>
                            </div>
                          </If>
                        </div>
                      </div>

                      <div className="divider full"></div>
                    </div>
                  </If>
                </div>
              )}
            />
          </div>
        </div>

        <button
          className="arrow right-4 top-[175px] absolute text-white hover:text-orange hidden md:block"
          onClick={scrollNext}
        >
          <svg
            width="38"
            height="71"
            viewBox="0 0 38 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.94531 3L32.9994 35.8154L2.94531 68.6309"
              stroke="currentColor"
              strokeWidth="6"
              className="transition-all duration-300 ease-in-out"
            />
          </svg>

          <span className="sr-only">Next</span>
        </button>
        <button
          className="arrow left-4 top-[175px] absolute text-white hover:text-orange hidden md:block"
          onClick={scrollPrev}
        >
          <svg
            width="37"
            height="71"
            viewBox="0 0 37 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.6362 3L4.58219 35.8154L34.6362 68.6309"
              stroke="currentColor"
              strokeWidth="6"
              className="transition-all duration-300 ease-in-out"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </button>

        <div className="flex justify-center gap-2 mt-4 items-center">
          <For
            each={thumbnails}
            render={(thumb, idx) => {
              return (
                <button
                  onClick={() => {
                    emblaApi?.scrollTo(idx);
                    setCurrentIndex(idx);
                  }}
                  className={`border border-black ${
                    currentIndex === idx ? "bg-black" : "bg-transparent"
                  } aspect-square w-3 h-3 rounded-full overflow-hidden`}
                >
                  {idx}
                </button>
              );
            }}
          />
        </div>
      </div>

      <div className="flex justify-center gap-2 mt-0 items-center">
        <For
          each={thumbnails}
          render={(thumb, idx) => {
            return (
              <button
                onClick={() => {
                  emblaApi?.scrollTo(idx);
                  setCurrentIndex(idx);
                }}
                className={`border border-text carousel-btn ${
                  currentIndex === idx ? "bg-text" : "bg-transparent"
                } aspect-square w-[10px] h-[10px] rounded-full overflow-hidden`}
              >
                {idx}
              </button>
            );
          }}
        />
      </div>
    </section>
  );
};

export default Carousel;

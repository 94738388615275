"use client";

import { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import Play from "./icons/Play";
import If from "./If";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntersection } from "@/hooks/useIntersection";
import { Volume } from "lucide-react";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export default function Video({
  src = "",
  title = "Video",
  className = "",
  ...props
}) {
  const ref = useRef(null);
  const [playerSrc, setPlayerSrc] = useState(src);
  const [player, setPlayer] = useState(null);
  const [state, setState] = useState("idle");
  const backgroundEle = useRef(null);

  const { observerRef, isIntersecting } = useIntersectionObserver({
    onIntersect: (target) => {
      // console.log("intersected");
      if (!ref?.current) return;
      ref?.current?.play();
      // console.log("play");
    },
    onNotIntersect: (target) => {
      if (!ref?.current) return;
      ref?.current?.pause();
      setState("loop");
    },
  });

  // useIntersectionObserver({
  //   : () => {
  //     console.log("intersected");
  //     if (!ref?.current) return;
  //     ref?.current?.play();
  //     console.log("play");
  //   },
  //   remove: () => {
  //     if (!ref?.current) return;
  //     ref?.current?.pause();
  //     setState("loop");
  //   },
  // });

  useGSAP(
    () => {
      if (!gsap || !backgroundEle.current || typeof window === "undefined") {
        return;
      }

      const tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: backgroundEle.current,
          start: "top bottom-=15%",
          end: `bottom bottom`,
          scrub: 0.5,
        },
      });

      tl.to(backgroundEle.current, {
        // clipPath: "inset(0% 0% 0% 0% round 0)",
        borderRadius: "0",
        transform: "translateY(0) scale(1)",
        ease: "none",
        onEnter: () => {},
        onEnterBack: () => {},
        onComplete: () => {
          // backgroundEle.current.style.clipPath = "inset(0 0 0 0 round 0)";
        },
      });
    },
    {
      dependencies: [backgroundEle.current],
    }
  );

  // useEffect(() => {
  //   if (!src) return;
  //   // const _player = new Player(ref.current, {
  //   //   url: playerSrc,
  //   // });

  //   // _player.controls = false;

  //   // // _player.on("play", () => setState("playing"));
  //   // // _player.on("pause", () => setState("paused"));
  //   // // _player.on("ended", () => setState("ended"));

  //   // setPlayer(_player);

  //   // return () => _player.destroy();
  // }, [playerSrc]);

  return (
    <div
      key={playerSrc}
      className={`relative z-0 w-full ${className}`}
      ref={observerRef}
    >
      <div
        className="relative z-[1] overflow-hidden h-full"
        ref={backgroundEle}
        style={{
          // clipPath: "inset(5% 5% 5% 5% round 2rem)",
          borderRadius: "2rem",
          transform: "translateY(100px) scale(0.95)",
          transformOrigin: "center",
        }}
      >
        {/* <iframe
          width="100%"
          height="100%"
          src={playerSrc}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          className="aspect-video w-full"
          loading="lazy"
          title={title}
        /> */}

        <If condition={state !== "playing"}>
          <video
            className="aspect-video w-full object-contain object-center h-full"
            preload="none"
            // autoPlay
            loop
            muted
            playsInline
            src={playerSrc}
            ref={ref}
            onMouseEnter={(ev) => {
              ev.target.controls = true;
            }}
            onMouseLeave={(ev) => {
              ev.target.controls = false;
            }}
          />
        </If>

        <If condition={state === "playing"}>
          <video
            className="aspect-video w-full object-contain object-center h-full"
            preload="none"
            autoPlay
            controls
            loop
            muted={false}
            src={playerSrc}
            ref={ref}
          />
        </If>

        {/* <If condition={state !== "playing"}>
          <div className="absolute inset-0 rounded overflow-hidden z-10">
            <span className="sr-only">Play video</span>
            <button
              className="absolute bottom-2 md:bottom-10 right-2 md:right-10 bg-yellow aspect-square flex justify-center items-center rounded-full z-10 w-[60px] md:w-[88px]"
              onClick={() => {
                if (state === "playing") {
                  setState("loop");
                } else {
                  setState("playing");
                }
              }}
            >
              <span className="sr-only">Play</span>
              <svg
                className="w-[30px] lg:w-[42px]"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0833 14.5833L17.5 26.25H5.83331V43.75H17.5L32.0833 55.4167V14.5833Z"
                  stroke="black"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M45.325 24.675C48.0589 27.4098 49.5948 31.1184 49.5948 34.9854C49.5948 38.8524 48.0589 42.5611 45.325 45.2958"
                  stroke="black"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M55.6208 14.3792C61.0888 19.8487 64.1605 27.266 64.1605 35C64.1605 42.734 61.0888 50.1513 55.6208 55.6208"
                  stroke="black"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </If> */}
      </div>
    </div>
  );
}

"use client";

import Button from "@/components/Button";
import If from "@/components/common/If";
import RichText from "@/components/common/RichText";
import { useState } from "react";
import Image from "@/components/common/Image";
import { X } from "lucide-react";

export default function Modal({ title, content, children }: any) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <Button className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        {title}
      </Button>
      <If condition={isOpen}>
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-10"></div>
        <div className="fixed top-0 left-0 w-full h-full z-[20]">
          <div className="flex flex-col justify-center items-center w-full h-full">
            <div className="w-[800px] max-w-full mx-auto relative">
              <button
                className="top-6 right-4 absolute z-[10]"
                onClick={() => setIsOpen(false)}
              >
                <X color="black" size={38} />
              </button>
              <div className="bg-white rounded-lg shadow-xl p-8 relative stack gap-8">
                <h2 className="text-h3 font-hero uppercase font-medium">
                  {title}
                </h2>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                  <Image
                    src={content?.image?.url}
                    alt={content?.image?.alt}
                    width={content?.image?.width}
                    height={content?.image?.height}
                    imgClassName="aspect-square object-top"
                  />

                  <div className="mt-8">
                    <RichText json={content?.text?.json} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </If>
    </div>
  );
}

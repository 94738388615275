import { options } from "@/config/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function RichText({
  json,
  className,
}: {
  json: any;
  className?: string;
}) {
  return (
    <div className={className}>{documentToReactComponents(json, options)}</div>
  );
}

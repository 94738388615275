import { options } from "@/config/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function RichText({ json }: { json: any }) {
  return (
    <div className="rich-text stack gap-4">
      {documentToReactComponents(json, options)}
    </div>
  );
}

"use client";

import React, { useEffect, useRef, useState } from "react";
import styles from "./WhyWereDifferent.module.scss";
import For from "@/components/For";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import AnimateIn from "@/components/AnimateIn";
import If from "@/components/If";
import Link from "next/link";
import { ChevronRight } from "react-feather";
import { Details } from "../Details";
import { ArrowLeft, ArrowRight, X } from "lucide-react";
import Video from "@/components/Video";
import { useLenis } from "lenis/react";
import RichText from "@/components/RichText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

const sections = [
  {
    title: "Estimating",
    description:
      "Our estimators are proficient at assessing designs in development and creating budgets that accurately anticipate future needs. Transparency is a priority at Lighthouse. Detailed dialogue amongst departments and open conversations with the client from the offset allow all parties to move forward with clear expectations and a full understanding of the project.",
  },
  {
    title: "Engineering",
    description:
      "Engineers are present throughout the Lighthouse pipeline to guarantee that the final product best serves the end-user. The Engineering Department also facilitates communication and collaboration between engineers at different stages of the project, ensuring seamless transition and project translation between touch points.",
  },
  {
    title: "Planning",
    description:
      "Our planners take the project design and translate it into bite-sized pieces that are comprehensive, more easily managed and in accordance with Lighthouse workflows. Planning Layout Design Specialists use their field experience to game plan the electrical layout while Virtual Design & Construction Specialists create 3D models.",
  },
  {
    title: "Supply Chain",
    description:
      "Our specialists coordinate within the planning stage to determine which materials are needed for construction to make strategic purchases. Once materials are obtained, they facilitate their allocation, whether it be to pre-fabrication, on-site or into storage until needed.",
  },
  {
    title: "Pre-Fabrication",
    description:
      "By fabricating assemblies and modules off-site before arrival, we enhance control over work, timelines, and budgets. This ensures high quality and creates efficiencies at scale. Our pre-fabrication team of experienced craftsmen efficiently prepares assets for field assembly, leveraging their combined expertise.",
  },
  {
    title: "Logistics",
    description:
      "Our logistics team works in tandem with the supply chain to facilitate the movement of materials, tools and information from design to pre-fabrication to the point of installation.",
  },
  {
    title: "Field",
    description:
      "All work is done to best support our workers in the field who integrate and install our electrical components. Our field workers deliver best results, executing with exceptional precision and taking the lead in coordinating with other trades involved in projects.",
  },
  {
    title: "Scheduling",
    description:
      "Our scheduling team is responsible for creating and maintaining a project schedule that is both realistic and achievable. By working closely with the project management team, our schedulers ensure that the project is on track and that all milestones are met.",
  },
  {
    title: "EVM",
    description:
      "Our project controls team monitors the overall health of each project by utilizing two key performance indexes: Schedule Performance and Cost Performance. By tracking a project’s earned value, planned value, and actual cost, we can evaluate a proactive understanding of where each project is and its trajectory. This way we can raise and address areas of concern before they are fully realized.",
  },
  {
    title: "Safety",
    description:
      "Safety is of the first order. We’ve been in those trenches. We’ve seen the faults and we know the fixes. So we plan safe practices by thinking through every step of the process, aiming to eliminate potential hazards in the blueprints– before boots even hit the ground. By utilizing the best tools and materials we can make installations safe. By relying on standardized processes, meticulously improved by regular feedback, we can ensure all employees clock out at the end of the day.",
  },
];

const tabData = [
  { id: "estimating", label: "ESTIMATING" },
  { id: "engineering", label: "ENGINEERING" },
  { id: "planning", label: "PLANNING" },
  { id: "supplyChain", label: "SUPPLY CHAIN" },
  { id: "preFabrication", label: "PRE-FABRICATION" },
  { id: "logistics", label: "LOGISTICS" },
  { id: "field", label: "FIELD" },
  { id: "scheduling", label: "SCHEDULING" },
  { id: "evm", label: "EVM" },
  { id: "safety", label: "SAFETY" },
];

const tabContent = {
  estimating: {
    title: "ESTIMATING",
    content:
      "Our estimators are proficient at assessing designs in development and creating budgets that accurately anticipate future needs. Transparency is a priority at Lighthouse. Detailed dialogue amongst departments and open conversations with the client from the offset allow all parties to move forward with clear expectations and a full understanding of the project.",
  },
  engineering: {
    title: "ENGINEERING",
    content:
      "Engineers are present throughout the Lighthouse pipeline to guarantee that the final product best serves the end-user. The Engineering Department also facilitates communication and collaboration between engineers at different stages of the project, ensuring seamless transition and project translation between touch points.",
  },
  planning: {
    title: "PLANNING",
    content:
      "Our planners take the project design and translate it into bite-sized pieces that are comprehensive, more easily managed and in accordance with Lighthouse workflows. Planning Layout Design Specialists use their field experience to game plan the electrical layout while Virtual Design & Construction Specialists create 3D models.",
  },
  supplyChain: {
    title: "SUPPLY CHAIN",
    content:
      "Our specialists coordinate within the planning stage to determine which materials are needed for construction to make strategic purchases. Once materials are obtained, they facilitate their allocation, whether it be to pre-fabrication, on-site or into storage until needed.",
  },
  preFabrication: {
    title: "PRE-FABRICATION",
    content:
      "By fabricating assemblies and modules off-site before arrival, we enhance control over work, timelines and budgets. This ensures high quality and creates efficiencies at scale. Our pre-fabrication team of experienced craftsmen efficiently prepares assets for field assembly, leveraging their combined expertise.",
  },
  logistics: {
    title: "LOGISTICS",
    content:
      "Our logistics team works in tandem with the supply chain to facilitate the movement of materials, tools and information from design to pre-fabrication to the point of installation.",
  },
  field: {
    title: "FIELD",
    content:
      "All work is done to best support our workers in the field who integrate and install our electrical components. Our field workers deliver best results, executing with exceptional precision and taking the lead in coordinating with other trades involved in projects.",
  },
  scheduling: {
    title: "SCHEDULING",
    content:
      "Our scheduling team is responsible for creating and maintaining a project schedule that is both realistic and achievable. By working closely with the project management team, our schedulers ensure that the project is on track and that all milestones are met.",
  },
  evm: {
    title: "EVM",
    content:
      "Our project controls team monitors the overall health of each project by utilizing two key performance indexes: Schedule Performance and Cost Performance. By tracking a project’s earned value, planned value and actual cost, we can evaluate a proactive understanding of where each project is and its trajectory. This way we can raise and address areas of concern before they are fully realized.",
  },
  safety: {
    title: "SAFETY",
    content:
      "Safety is of the first order. We’ve been in those trenches. We’ve seen the faults and we know the fixes. So we plan safe practices by thinking through every step of the process, aiming to eliminate potential hazards in the blueprints– before boots even hit the ground. By utilizing the best tools and materials we can make installations safe. By relying on standardized processes, meticulously improved by regular feedback, we can ensure all employees clock out at the end of the day.",
  },
};

const WhyWereDifferent = ({ data = [], video = "", ...props }) => {
  console.log(data);
  const [activeTab, setActiveTab] = useState("");

  const timeline = useRef(null);

  const tabsRef = useRef([]);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const anotherRef = useRef(null);
  const tabContainerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const lenis = useLenis();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [direction, setDirection] = useState("down");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > prevScrollPos;
      setDirection(isScrollingDown ? "down" : "up");
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useGSAP(
    () => {
      const containerWidth = containerRef.current.scrollWidth;
      const viewportWidth = window.innerWidth;
      const bufferWidth = viewportWidth; // 30% of viewport as buffer
      const tl = gsap.timeline({
        defaults: { ease: "none" },
        scrollTrigger: {
          trigger: contentRef.current,
          invalidateOnRefresh: true,
          start: "top top",
          end: "500%",
          scrub: true,
          pin: true,
          normalizeScroll: true,
          onEnter: () => {
            setShow(true);
            setCurrentTab(0);
          },
          onLeaveBack: () => {
            setShow(false);
            setCurrentTab(0);
          },
          onEnterBack: () => {
            setShow(true);
            setCurrentTab(tabData.length - 1);
          },
          onLeave: () => {
            setShow(false);
            setCurrentTab(tabData.length - 1);
          },
          onUpdate: (self) => {
            //what is the current element in the middle of the screen
            const currentElement = tabsRef.current.find((tab, idx) => {
              const rect = tab.getBoundingClientRect();
              const isCenter =
                rect.left <= window.innerWidth / 2 &&
                rect.right >= window.innerWidth / 2;
              if (isCenter) {
                if (
                  window.innerWidth < 1024 &&
                  idx < tabsRef.current.length - 1
                ) {
                  tabContainerRef.current?.scrollTo({
                    left: idx * 100,
                  });
                }
                if (currentTab !== idx) {
                  setCurrentTab(idx);
                }
              }
              return isCenter;
            });
            // console.log(currentElement);
          },
          // anticipatePin: 1,
        },
      });
      timeline.current = tl;

      // Animate the base line drawing
      tl.fromTo(
        containerRef.current,
        { x: 0, transformOrigin: "left center" },
        {
          x: () => -(containerWidth - viewportWidth + 48),
        },
      );

      // sections.forEach((section, i) => {
      //   tl.addLabel(section.title, i);
      // });
    },
    {
      dependencies: [tabsRef.current, contentRef.current, containerRef.current],
    },
  );

  const handleSkipBack = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const containerHeight = containerRef.current.scrollWidth;
    const top =
      direction === "up"
        ? document.body.scrollHeight - containerRef.current.scrollWidth
        : document.body.scrollHeight;

    lenis?.scrollTo(document.querySelector("#start").offsetTop, {
      duration: 2,
    });
    // ScrollTrigger.update();
  };

  const handleSkipForward = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const containerHeight = containerRef.current.scrollWidth;
    const top =
      direction === "up"
        ? document.body.scrollHeight - containerRef.current.scrollWidth
        : document.body.scrollHeight;

    lenis?.scrollTo(document.body.scrollHeight, { duration: 2 });
    // ScrollTrigger.update();
  };

  return (
    <div ref={anotherRef} className="relative" id="start">
      <div
        className={`fixed left-4 bottom-4 z-[100] flex gap-6 transition-all ease-in-out duration-300 ${
          show ? "opacity-100" : "opacity-0"
        }`}
      >
        <button className={`text-orange`} onClick={handleSkipBack}>
          <ArrowLeft size={42} />
        </button>
        <button className={`text-orange`} onClick={handleSkipForward}>
          <ArrowRight size={42} />
        </button>
      </div>
      <div className="stack gap-4 relative" ref={contentRef}>
        <div className="flex w-[1300px] max-w-full mx-auto mt-10 h-[30dvh] md:h-[50dvh]">
          <Video src={video || "/video/process-web.mp4"} title="Our Process" />
        </div>
        <div
          className="flex px-8 xl:justify-center items-center gap-8 overflow-auto "
          ref={tabContainerRef}
        >
          {data.map((section, i) => (
            <button
              key={section.title}
              className={`tab text-[#6C6C6C] hover:text-orange transition-all duration-300 ease-in-out whitespace-nowrap ${
                activeTab === tabData[i].id || currentTab === i
                  ? "active text-orange scale-125"
                  : ""
              }`}
              id={`tab-${i}`}
              onClick={() => {
                // const scroll = timeline.current.scrollTrigger.labelToScroll(
                //   section.title
                // );
                const targetElem = tabsRef.current[i];
                const totalScroll =
                  timeline.current.scrollTrigger.end -
                  timeline.current.scrollTrigger.start;

                // Calculate the total width including padding and spacing
                const elementWidth = targetElem.offsetWidth;
                const totalWidth =
                  sections.length * elementWidth +
                  window.innerWidth * 0.35 + //* 2 + // Left and right padding
                  48 * (sections.length - 1); // Spacing between elements

                // Calculate the left position of the target element including padding and spacing
                const targetLeft = 48 + i * (elementWidth + 48);

                // Calculate the scroll position
                const scrollRatio =
                  targetLeft / (totalWidth - window.innerWidth);
                const y = Math.floor(
                  timeline.current.scrollTrigger.start +
                    scrollRatio * totalScroll,
                );

                // scrollTo({ top: y, behavior: "smooth" });
                lenis?.scrollTo(y, { duration: 2 });
              }}
            >
              {section.title}
            </button>
          ))}
        </div>
        <div className="inner overflow-hidden">
          <div className="flex gap-12 px-[35dvw]" ref={containerRef}>
            {data.map((section, i) => (
              <div
                key={section.title}
                className="flex-process flex justify-center"
                ref={(el) => (tabsRef.current[i] = el)}
              >
                <div className="stack gap-2 bg-gray px-6 py-6 h-max min-h-[328px] md:min-h-[250px]">
                  <h3 className="text-2xl xl:text-3xl font-regular font-hero uppercase text-orange">
                    {section.title}
                  </h3>

                  <div>
                    <RichText
                      json={section.detailLeftColumn?.json}
                      className="text-base xl:text-lg"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyWereDifferent;

import { useEffect, useRef } from "react";

export const useIntersection = ({
  action = () => {},
  remove = () => {},
  threshold = 0,
}) => {
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && action) {
            action();
          } else {
            if (remove) {
              remove();
            }
          }
        });
      },
      {
        root: null,
        rootMargin: "0px 0px 50px 0px",
        threshold,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ref, action, remove]);

  return { ref };
};
